<template>
  <div class="panel">
    <div class="panel__content">
      <div class="panel__header">
        <h2 class="panel__title panel__title--minor">
          Top
          <br />
          <b>Marketplaces</b>
        </h2>
      </div>
      <div class="panel__body">
        <div class="empty">
          <b-table class="table__topaffiliates" :items="tableData.items"></b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numberToReal } from "@/utils/money";
import { listarVendasPorCanal } from "@/api/afiliados";

export default {
  components: {},
  data() {
    return {
      tableData: {},
    };
  },
  computed: {
    getTable() {
      return this.$store.state.vendas.vendasCanal;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    listarVendasPorCanal,
    loadData() {
      let date = new Date();
      let primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
      let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.listarVendasPorCanal(primeiroDia, ultimoDia);
    },
    loadTable(value) {
      const items = [];

      if (value.data) {
        value.data.map(line => {
          items.push({
            canal: line.affiliateId,
            receita: numberToReal(line.valor_vendido),
          });
        });
      }

      let data = {
        title: {
          top: "",
          name: "Detalhamento",
        },
        items,
      };
      return (this.tableData = data);
    },
  },
  watch: {
    getTable(data) {
      this.loadTable(data);
    },
  },
};
</script>

<style lang="scss">
.panel {
  height: 100%;
}
.table__topaffiliates th,
.table__topaffiliates td {
  border-top: none;
  padding: 20px;
}
.table__topaffiliates {
  margin: 0;
  thead {
    display: none !important;
    padding: 0;
    border: none;
  }
  tbody {
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
    td {
      font-weight: bold !important;
      white-space: nowrap;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}
</style>
