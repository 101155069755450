<template>
  <div class="dashboard">
    <Primary />
  </div>
</template>

<script>
import Primary from "@/components/dashboard/Primary.vue";

export default {
  components: {
    Primary,
  },
};
</script>

<style lang="scss" scoped></style>
