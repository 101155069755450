<template>
  <div class="primary">
    <ModalPlan :showModal="false"></ModalPlan>
    <div class="Bar last_update">
      <p>
        <span>{{ lastUpdate | filterDataHora }}</span>
      </p>
    </div>
    <div class="Products">
      <TopProducts />
    </div>
    <div class="Value">
      <Value />
    </div>
    <div class="Affiliate">
      <TopAffiliates />
    </div>
    <div class="Tickets">
      <Tickets />
    </div>
    <div class="Orders">
      <Orders />
    </div>
    <div class="Brands">
      <TopBrands />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Value from "@/components/value/Value.vue";
import TopProducts from "@/components/products/TopProducts.vue";
import TopAffiliates from "@/components/affiliates/TopAffiliates.vue";
import Tickets from "@/components/tickets/TicketsV2.vue";
import Orders from "@/components/orders/OrdersV2.vue";
import TopBrands from "@/components/brands/TopBrands.vue";
import { listarNumerosPedidos, listarNumerosProdutos, listarNumerosMarcas } from "@/api/dashboard";
import { filterDataHora } from "@/utils/date";
import ModalPlan from "@/components/modal-plan/ModalPlan.vue";

export default {
  data() {
    return {
      counterInterval: null,
      lastUpdateLocal: new Date(),
      showModal: false,
    };
  },
  components: {
    Value,
    TopProducts,
    TopAffiliates,
    Tickets,
    Orders,
    TopBrands,
    ModalPlan,
  },
  filters: {
    filterDataHora,
  },
  methods: {
    listarNumerosPedidos,
    listarNumerosProdutos,
    listarNumerosMarcas,
    loadData() {
      this.listarNumerosPedidos();
      this.listarNumerosProdutos();
      this.listarNumerosMarcas();
      this.lastUpdateLocal = new Date();
    },
  },
  computed: {
    ...mapState({
      accountActive: state => state.accounts.accountActive,
      lastUpdate: state => state.dashboard.lastUpdate,
    }),
  },
  created: function() {
    return this.loadData();
  },
  watch: {
    accountActive(data) {
      if (data) this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.primary {
  display: grid;
  height: 100%;
  position: relative;
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr 2fr;
  gap: 1rem 1rem;
  grid-template-areas:
    "Bar Bar Bar Bar Bar Bar"
    "Value Value Value Value Affiliate Products"
    "Value Value Value Value Affiliate Products"
    "Orders Orders Tickets Tickets Tickets Products"
    "Brands Brands Brands Brands Brands Brands";
}
.Products {
  grid-area: Products;
}
.Value {
  grid-area: Value;
}
.Hits {
  grid-area: Hits;
}
.Tickets {
  grid-area: Tickets;
}
.Orders {
  grid-area: Orders;
}
.Brands {
  grid-area: Brands;
}

@media only screen and (max-width: 991px) {
  .primary {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
    grid-template-columns: 1fr 0fr;
    gap: 1rem 0rem;
    grid-template-areas:
      "Value"
      "Affiliate"
      "Orders"
      "Tickets"
      "Products"
      "Brands";
  }
  .Products {
    order: 1;
  }
  .Value {
    order: 0;
  }
  .Hits {
  }
  .Tickets {
    order: 3;
  }
  .Orders {
    order: 4;
  }
  .Brands {
    order: 5;
  }
  .Affiliate {
    order: 2;
  }
}

.Bar {
  grid-area: Bar;
  max-height: 40px;
}
.Products {
  grid-area: Products;
  @media only screen and (max-width: 991px) {
    max-width: 991px;
    width: 100%;
  }
}
.Value {
  grid-area: Value;
}
.Hits {
  grid-area: Hits;
}
.Tickets {
  grid-area: Tickets;
}
.Orders {
  grid-area: Orders;
}
.Brands {
  grid-area: Brands;
}
.Affiliate {
  grid-area: Affiliate;
}
.last_update {
  display: flex;

  > * {
    text-align: left;
    width: 100%;
  }

  > div {
    text-align: left;
    width: 30%;
  }
}
</style>
