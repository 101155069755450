<template>
  <div class="panel">
    <div class="panel__content">
      <div class="panel__header">
        <h2 class="panel__title panel__title--minor">
          Receita
          <br />
          <b>Total de pedidos</b>
        </h2>
        <!-- <h2 class="panel__title">
          Valor total de pedidos
        </h2> -->
      </div>
      <div class="panel__body">
        <div class="empty" v-if="!pedidosHoje || !pedidosOntem || !pedidosMesAtual">
          <p>Carregando...</p>
        </div>

        <!-- Desktop -->

        <table class="value__table" v-if="pedidosHoje && pedidosOntem && pedidosMesAtual && innerWidth >= 992">
          <thead>
            <tr>
              <th></th>
              <th>Ontem</th>
              <th class="value__highlight value__highlight-top">
                <span>Hoje</span>
              </th>
              <th>Mês Atual</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h3 class="data_bullet data_bullet-green">Faturado</h3>
              </td>
              <td>
                <span class="number--small" v-if="pedidosOntem">
                  {{ pedidosOntem.valor_faturado | numberToReal }}
                </span>
              </td>
              <td class="data_table__highlight">
                <span class="number--small" v-if="pedidosHoje">
                  {{ pedidosHoje.valor_faturado | numberToReal }}
                </span>
              </td>
              <td>
                <span class="number--small" v-if="pedidosMesAtual">
                  {{ pedidosMesAtual.valor_faturado | numberToReal }}

                  <span 
                    :class="compare.valor_faturado >= 0 ? 'badge badge--success' : 'badge'"
                    v-if="compare"
                    title="Comparado ao mesmo período do mês anterior"
                    v-b-tooltip.hover.top>{{compare.valor_faturado | percentage}}</span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h3 class="data_bullet data_bullet-lightBlue">
                  Vendido
                </h3>
              </td>
              <td>
                <span class="number--small" v-if="pedidosOntem">
                  {{ pedidosOntem.valor_realizado | numberToReal }}
                </span>
              </td>
              <td class="data_table__highlight">
                <span class="number--small" v-if="pedidosHoje">
                  {{ pedidosHoje.valor_realizado | numberToReal }}
                </span>
              </td>
              <td>
                <span class="number--small" v-if="pedidosMesAtual">
                  {{ pedidosMesAtual.valor_realizado | numberToReal }}

                  <span 
                    :class="compare.valor_realizado >= 0 ? 'badge badge--success' : 'badge'"
                    v-if="compare"
                    title="Comparado ao mesmo período do mês anterior"
                    v-b-tooltip.hover.top>{{compare.valor_realizado | percentage}}</span>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h3 class="data_bullet data_bullet-orange">
                  Cancelado
                </h3>
              </td>
              <td>
                <span class="number--small" v-if="pedidosOntem">{{ pedidosOntem.valor_cancelado | numberToReal }}</span>
              </td>
              <td class="data_table__highlight data_table__highlight-bottom">
                <span class="number--small" v-if="pedidosHoje">{{ pedidosHoje.valor_cancelado | numberToReal }}</span>
              </td>
              <td>
                <span class="number--small" v-if="pedidosMesAtual">
                  {{ pedidosMesAtual.valor_cancelado | numberToReal }}
                  
                  <span 
                    :class="compare.valor_cancelado >= 0 ? 'badge badge--success' : 'badge'"
                    v-if="compare"
                    title="Comparado ao mesmo período do mês anterior"
                    v-b-tooltip.hover.top>{{compare.valor_cancelado | percentage}}</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Mobile -->

        <table class="" v-if="pedidosHoje && pedidosOntem && pedidosMesAtual && innerWidth < 992">
          <thead>
            <tr>
              <th>(R$)</th>
              <th>
                <h3 class="data_bullet-green">Faturado</h3>
              </th>
              <th>
                <h3 class="data_bullet-lightBlue">Vendido</h3>
              </th>
              <th>
                <h3 class="data_bullet-orange">Cancelado</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Hoje</td>
              <td>
                <span class="number--small" v-if="pedidosHoje">{{ pedidosHoje.valor_faturado | numberToReal(false) }}</span>
              </td>
              <td>
                <span class="number--small" v-if="pedidosHoje">{{ pedidosHoje.valor_realizado | numberToReal(false) }}</span>
              </td>
              <td>
                <span class="number--small" v-if="pedidosHoje">{{ pedidosHoje.valor_cancelado | numberToReal(false) }}</span>
              </td>
            </tr>
            <tr>
              <td>Ontem</td>
              <td>
                <span class="number--small" v-if="pedidosOntem">{{ pedidosOntem.valor_faturado | numberToReal(false) }}</span>
              </td>
              <td>
                <span class="number--small" v-if="pedidosOntem">{{ pedidosOntem.valor_realizado | numberToReal(false) }}</span>
              </td>
              <td>
                <span class="number--small" v-if="pedidosOntem">{{ pedidosOntem.valor_cancelado | numberToReal(false) }}</span>
              </td>
            </tr>
            <tr>
              <td>Mês Atual</td>
              <td>
                <span class="number--small" v-if="pedidosMesAtual">
                  {{ pedidosMesAtual.valor_faturado | numberToReal(false) }}
                
                  <span 
                      :class="compare.valor_faturado >= 0 ? 'badge badge--success' : 'badge'"
                      v-if="compare"
                      title="Comparado ao mesmo período do mês anterior"
                      v-b-tooltip.hover.top>{{compare.valor_faturado | percentage}}</span>
                </span>
              </td>
              <td>
                <span class="number--small" v-if="pedidosMesAtual">
                  {{ pedidosMesAtual.valor_realizado | numberToReal(false) }}
                
                  <span 
                      :class="compare.valor_realizado >= 0 ? 'badge badge--success' : 'badge'"
                      v-if="compare"
                      title="Comparado ao mesmo período do mês anterior"
                      v-b-tooltip.hover.top>{{compare.valor_realizado | percentage}}</span>
                </span>
              </td>
              <td>
                <span class="number--small" v-if="pedidosMesAtual">
                  {{ pedidosMesAtual.valor_cancelado | numberToReal(false) }}
                
                  <span 
                      :class="compare.valor_cancelado >= 0 ? 'badge badge--success' : 'badge'"
                      v-if="compare"
                      title="Comparado ao mesmo período do mês anterior"
                      v-b-tooltip.hover.top>{{compare.valor_cancelado | percentage}}</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { numberToReal } from "@/utils/money";
import { percentage } from "@/utils/masks";
export default {
  props: ["componentes"],
  components: {
  },
  filters: {
    numberToReal,
    percentage,
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      data: {
        title: {
          top: "",
          name: "Valor total de pedidos",
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.innerWidth = window.innerWidth;
    },
  },
  computed: {
    pedidosHoje() {
      return this.$store.state.dashboard.pedidosHoje;
    },
    pedidosOntem() {
      return this.$store.state.dashboard.pedidosOntem;
    },
    pedidosMesAtual() {
      return this.$store.state.dashboard.pedidosMesAtual;
    },
    compare() {
      return this.$store.state.dashboard.compare;
    },
  },
};
</script>

<style lang="scss">
.panel {
  height: 100%;

  &__content {
    background: $white;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    color: $dark;
    display: flex;
    padding: 26px 36px;
    width: 100%;
  }

  &__title {
    @include font-size(2.6);
    color: $dark;
    font-family: $font__main;
    display: inline-block;

    &::after {
      content: "";
      position: relative;
      background: $blue;
      display: block;
      width: 4rem;
      height: 0.3rem;
      bottom: 0;
    }
  }

  &__subtitles {
    height: 100%;
    width: 100%;
  }

  &__subtitle {
    @include font-size(1.62);
    font-family: $font__main;
    padding: 1.3rem 0 1rem;
    text-align: left;
    display: flex;
    align-items: center;
    font-weight: 600;

    &::before {
      content: "";
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      margin-right: 1rem;
    }

    &__highlight {
      box-shadow: 0px 17px 26px 2px rgba(0, 0, 0, 0.1);
      background: #fff;

      &-top {
        box-shadow: 0px 5px 26px 2px rgba(0, 0, 0, 0.1);
        border-radius: 1rem 1rem 0 0;
      }

      &-bottom {
        box-shadow: 0px 17px 26px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 1rem 1rem;
      }
    }
  }

  &__highlight {
    box-shadow: 0px 17px 26px 2px rgba(0, 0, 0, 0.1);
    background: #fff;

    &-top {
      box-shadow: 0px 5px 26px 2px rgba(0, 0, 0, 0.1);
      border-radius: 1rem 1rem 0 0;
    }

    &-bottom {
      box-shadow: 0px 17px 26px 2px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 1rem 1rem;
    }
  }

  &__table {
    //width: 32vw;
    width: 59vw;
    tr {
      td {
        font-family: $font__main;
        @include font-size(1.05);
        color: $dark;
        font-weight: 500;
        vertical-align: middle;
        padding: 0 !important;
        span {
          font-weight: 600;
        }
        &:last-child {
          text-align: center;
        }
      }

      th {
        font-family: $font__main;
        @include font-size(2.05);
        font-weight: 200;
        color: $dark;
        padding: 1rem 1.5rem !important;
        border-bottom: 0 !important;
        &:last-child {
          text-align: center;
        }
      }

      &:last-child {
        td {
          border-bottom: 0 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .value {
    &__content {
      padding: 15px;
    }
  }
}
</style>
