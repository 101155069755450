<template>
  <div class="panel">
    <div class="panel__content panel__content--horizontal">
      <div class="panel__header">
        <h2 class="panel__title panel__title--minor">
          Receita<br /><b>Por marca</b>
        </h2>
      </div>
      <div class="panel__body">
        <ul class="brands_contain" v-if="topMarcas.length === 0">
          <li class="brand__container empty">
            <p>Carregando...</p>
          </li>
        </ul>
        <ul class="brands_contain" v-if="topMarcas.length > 0">
          <!-- loop brand -->
          <li
            class="brand__container"
            v-for="marca in topMarcas"
            :key="marca.brandId"
          >
            <figure class="brand__figure">
              <h5>{{ marca.brandName }}</h5>
              <span class="badge badge--success"
                title="Participação nas vendas com base no mês atual"
                v-b-tooltip.hover.top>{{marca.period_percent | percentage(false) }}</span>
            </figure>
            <div class="brands__subtitle">
              <span class="brand__subtitle brand__subtitle-black">Mês atual</span>
              <span class="brand__subtitle">Ontem</span>
              <span class="brand__subtitle">Hoje</span>
            </div>
            <div class="brands__info">
              <span class="brand__info brand__info-black number--normal">
                {{ marca.valor_vendido.mes_atual | numberToReal }}
              </span>
              <span class="brand__info number--normal">
                {{ marca.valor_vendido.ontem | numberToReal }}
              </span>
              <span class="brand__info number--normal">
                {{ marca.valor_vendido.hoje | numberToReal }}
              </span>
            </div>
          </li>
          <!-- loop brand -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { numberToReal } from "@/utils/money";
import { percentage } from "@/utils/masks";
export default {
  props: ["componentes"],
  filters: {
    numberToReal,
    percentage,
  },
  computed: {
    topMarcas() {
      return this.$store.state.dashboard.marcas;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  height: 100%;

  &__content {
    max-width: 100%;
    width: 100%;
    overflow: scroll;
  }

  &__header {
    min-width: 185px !important;
  }

  .brand__figure {
    width: auto !important;
    margin: 0 !important;
    margin-right: 15px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h5 {
      font-size: 14px !important;
    }
  }

  .brands__value {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .brands__container {
    display: flex;
    width: 100%;
    background: $white;
    color: $dark;
    border-radius: 1rem;
    padding: 1rem 2rem;
    box-shadow: 0px 5px 10px rgba(2, 107, 126, 0.1);
  }

  .brands__content {
    display: flex;
  }

  .brands__title {
    @include font-size(1.56);
    color: $dark;
    font-family: $font__main;
    display: inline-block;

    &::after {
      content: "";
      position: relative;
      background: $blue;
      display: block;
      width: 4rem;
      height: 0.3rem;
      bottom: 0;
    }
  }

  .brands__strong {
    font-family: $font__main;
  }

  .brands_contain {
    display: flex;
    height: 100%;
  }

  .brand__container {
    display: flex;
    padding-left: 1.5vw;
    border-left: 1px solid $lightGray;
    margin-left: 1.5vw;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .brand__figure {
    width: 5vw;
    height: 5vw;
    box-sizing: border-box;
    overflow: hidden;
    margin-right: 1rem;
    margin-top: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }

    h5 {
      font-family: $font__main;
      font-weight: bold;
      font-size: 0.8vw;
      text-align: center;
    }
  }

  .brands__subtitle {
    margin-right: 1rem;
    // margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .brand__subtitle {
    display: flex;
    align-items: center;
    font-family: $font__main;
    @include font-size(1.05);
    color: $gray;
    flex: 1;
    font-size: 14px;
    &-black {
      color: $dark;
    }
  }

  .brands__info {
    line-height: 1.7rem;
    text-align: right;
    //margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .brand__info {
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: flex-end;
    font-family: $font__main;
    color: $gray;
    flex: 1;
    font-size: 16px;
    &-black {
      color: $dark;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
