<template>
  <div>
    <b-modal
      content-class="modalPlan"
      :visible="plano.showModal"
      :id="pagamentoSucesso ? 'modalPlan--sucesso' : 'modalPlan'"
      @hidden="closeModal"
      ref="modalPlan"
      hide-footer
      hide-header
    >
      <div class="modalPlan__container text-left">
        <div @click="closeModal" class="modalPlan__close" v-if="!plano.verifyTrial.expired">
          <img src="~@/assets/images/icones/close.svg" alt="Fechar" />
        </div>
        <div class="modalPlan__logo">
          <Logo />
        </div>
        <Plans></Plans>
      </div>
      <small class="modalPlan__credits" v-if="false"></small>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Logo from "@/components/logos/LogoPositivo.vue";
import Plans from "@/components/plans/Plans.vue";
export default {
  components: {
    Plans,
    Logo,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      plano: state => state.plano,
      pagamentoSucesso: state => state.pagamento.pagamentoSucesso,
    }),
  },
  methods: {
    closeModal() {
      this.$store.commit("pagamento/SET_PAYMENT_SUCCESSS", false);
      this.$store.commit("plano/SET_MODAL", false);
    },
  },
};
</script>

<style lang="scss">
#modalPlan--sucesso {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 620px;
      margin: 1.75rem auto;
    }
  }
  .modal-body {
    padding: 0;
  }
  .modal-content {
    border: 3px solid #ff4876;
    border-radius: 10px;
    padding: 50px;
    padding-bottom: 25px;
    @media screen and (max-width: 1290px) {
      margin: 0;
      width: auto;
    }
  }
  .modal-header {
    border-bottom: none;
  }
}
#modalPlan {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 740px;
      margin: 1.75rem auto;
    }
    @media screen and (max-width: 1290px) {
      width: 100%;
      max-width: max-content;
    }
  }
  .modal-body {
    padding: 0;
  }
  .modal-content {
    border: 3px solid #ff4876;
    border-radius: 10px;
    padding: 50px;
    padding-bottom: 25px;
  }
  .modal-header {
    border-bottom: none;
  }
}

.modalPlan {
  &__logo {
    margin-bottom: 40px;
    width: 155px;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    right: -40px;
    top: -40px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease;
    &:hover {
      transform: scale(1.2);
    }
  }
}
</style>
