<template>
	<div class="panel">
		<div class="panel__content">
			<div class="panel__header">
				<h2 class="panel__title panel__title--minor">
					Quantidade<br /><b>Total de pedidos</b>
				</h2>
			</div>
			<div class="panel__body">

				<div class="panel__numbers">
					<div class="panel__numbers__data --today">
						<h3 class="panel__numbers__data__label">Hoje</h3>
						<h4 class="panel__numbers__data__number">
							<span v-if="pedidosHoje" class="number--big">{{pedidosHoje.quantidade}}</span>
						</h4>
					</div>

					<div class="panel__numbers__data --yesterday">
						<h3 class="panel__numbers__data__label">Ontem</h3>
						<h4 class="panel__numbers__data__number">
							<span v-if="pedidosOntem" class="number--medium">{{pedidosOntem.quantidade}}</span>
						</h4>
					</div>

					<div class="panel__numbers__data --month">
						<h3 class="panel__numbers__data__label">Mês Atual</h3>
						<h4 class="panel__numbers__data__number">
							<span v-if="pedidosMesAtual" class="number--medium">
                {{pedidosMesAtual.quantidade}}

                <span 
                    :class="compare.quantidade >= 0 ? 'badge badge--success' : 'badge'"
                    v-if="compare"
                    title="Comparado ao mesmo período do mês anterior"
                    v-b-tooltip.hover.top>{{compare.quantidade | percentage}}</span>
              </span>
						</h4>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import { percentage } from "@/utils/masks";
export default {
  filters: {percentage},
  computed: {
    compare() {
      return this.$store.state.dashboard.compare;
    },
    pedidosAll() {
      return this.$store.state.dashboard.pedidosAll;
    },
    pedidosHoje() {
      return this.$store.state.dashboard.pedidosHoje;
    },
    pedidosOntem() {
      return this.$store.state.dashboard.pedidosOntem;
    },
    pedidosMesAtual() {
      return this.$store.state.dashboard.pedidosMesAtual;
    }
  }
};
</script>

<style lang="scss">
.orders__value {
 display: flex;
 width: 100%;
 height: 100%;
}

.orders__contain {
 display: flex;
 width: 100%;
 background: $white;
 color: $dark;
 border-radius: 1rem;
 padding: 1rem 2rem;
 box-shadow: 0px 5px 10px rgba(2, 107, 126, 0.1);
}

.orders__container {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
}

.orders__content {
  width: 100%;
  // min-width: 16rem;
  display: flex;
  flex-direction: column;
}

.orders__title {
 @include font-size(1.56);
 color: $dark;
 font-family: $font__main;
 display: inline-block;
 margin-bottom: 1rem;

 &::after {
  content: '';
  position: relative;
  background: $blue;
  display: block;
  width: 4rem;
  height: 0.3rem;
  bottom: 0;
 }
}

.orders__strong {
 font-family: $font__main;
}

.orders__subtitle {
  font-family: $font__main;
  @include font-size(1.06);
  color: $dark;
}

.orders__principal {
  font-family: $font__main;
  color: $dark;
  @include font-size(5);
}

.orders__regular {
  font-family: $font__main;
  color: $dark;
  @include font-size(3.5);
}

.orders__bottom {
  margin-top: auto;
}
</style>